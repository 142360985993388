
// Slick arrow change position
function changeposition() {
    var topPosition = ($('.slick-active').find(".image-container").height()/2);
    $('.slick-arrow').css('top',topPosition+'px');
  }

// Slider with carousel slick
$(".carousel-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight : true,
    responsive: [{
        breakpoint: 667,
        settings: {
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 2,
            swipe: true,
            dots: false,
        }
    }
    ]
}).on('afterChange',function(event){
    changeposition();
  }).trigger('afterChange');

  
var loadedRecomSlot = [];

function deplaceArrows() {
  $('.recently-v-arrows .slick-prev').insertBefore('.title-arrows-rv');
  $('.recently-v-arrows .slick-next').insertAfter('.title-arrows-rv');
  $('.complet-set-arrows .slick-prev').insertBefore('.title-arrows-cs');
  $('.complet-set-arrows .slick-next').insertAfter('.title-arrows-cs');
}

function applySlider(elementId) {

  var containerEinRecs = $('#' + elementId).find(".custom-recommendations");
  if (containerEinRecs.length > 0) {
    containerEinRecs.parents('.recommendations').removeClass("d-none");
    containerEinRecs.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '0',
      infinite: true,
      responsive: [{
        breakpoint: 667,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: '0',
          slidesToShow: 2,
          swipe: true,
          dots: false,
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      }
      ]
    });

    deplaceArrows();

    $(window).on('resize', function () {
      deplaceArrows();
    });
  }
}

function registerSliderEvents(target) {
  var observer = new WebKitMutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.target.id && mutation.target.id.startsWith("cq_recomm_slot-")) {

        if (loadedRecomSlot.indexOf(mutation.target.id) == -1) {
          loadedRecomSlot.push(mutation.target.id)
          setTimeout(function () {
            applySlider(mutation.target.id)
          }, 500);
        }
      }
    });
  });
  observer.observe(target, {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true
  });
}

var targets = document.querySelectorAll('[id^="cq_recomm_slot-"]');

for (var i = 0; i < targets.length; i++) {
  registerSliderEvents(targets[i])
}

$('.slider-recommendations-shop').parents('.shop-the-look-reco').removeClass("d-none");
$(".slider-recommendations-shop").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [{
        breakpoint: 667,
        settings: {
            arrows: true,
            slidesToShow: 2,
            swipe: true,
            dots:false,
        }
      },
      {
        breakpoint: 991.98,
        settings: {
            slidesToShow: 2,
            infinite: true
          }
      }
    ]
  });
  
  $('.shop-reco .slick-prev').insertBefore('.title-arrows-shop');
  $('.shop-reco .slick-next').insertAfter('.title-arrows-shop');

  $(".slider-pdp").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '0',
    infinite: true,
  });


  function CarouselHeightImage() {
    var windowWidth= $(window).width(),
        Carousel_width =(1/4 *(windowWidth));

     if ( windowWidth > 840 ) {
        var carouselHeight = (Carousel_width* 1.24);
     $('.carousel-slider .product-tile .image-container').css('height',carouselHeight);
     }else{
         var Carousel_width =(1/2 *(windowWidth)),
            carouselHeight = (Carousel_width* 1.24);
         $('.carousel-slider .product-tile .image-container').css('height',carouselHeight);
 
     }
 }
 CarouselHeightImage();
 
$(window).on('load resize', function () {
  changeposition();
  CarouselHeightImage();
});

$('.slider-block').slick({
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '0',
  infinite: true,
  arrows:false,
  autoplay:true,
  speed:1000,
  fade: true,
  swipe:true
});

$(document).ready(function () {
  var WindowWidth = $(window).width();
  var WindowHeight = $(window).outerHeight();
  var hauteur_fenetre = $('header').outerHeight();
  var heightImageSlider = WindowHeight - hauteur_fenetre;
  var videoSliderHeight = $('.slider-caroussel .video-mobile').height();
  if (WindowWidth < 768 ) {
      $('.slider-caroussel .slick-track').each(function() {
          if ($(this).hasClass('exist-video-mobile')) {
              $(this).find('.common-image-component').css('height', videoSliderHeight);
              $('.experience-tkooples_layouts-SKPSliderBlock').parent().closest('.region').css('height', videoSliderHeight);
          } else {
              $(this).find('.common-image-component').css('height', heightImageSlider);
              $('.experience-tkooples_layouts-SKPSliderBlock').parent().closest('.region').css('height', heightImageSlider);
          }
        });
  }
});